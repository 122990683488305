import "./styles.scss"

import React from "react"

import InvestmentTile from "components/InvestmentTile"
import ApartmentTile from "components/ApartmentTile"
import Button from "components/Button"

import { useFavoriteInvestments } from "context/investments-favorites"
import { useFavoriteApartments } from "context/apartments-favorites"

const Content = ({ type, setType }) => {
  const { favorite_investments } = useFavoriteInvestments()
  const { favorite_apartments } = useFavoriteApartments()

  const data =
    type === "investment" ? favorite_investments : favorite_apartments

  return (
    <section className="favorites-content">
      <div className="container-fluid">
        <div className="favorites-content__switch">
          <button
            onClick={() => setType("investment")}
            className={type === "investment" ? "current" : ""}
          >
            Inwestycje ({favorite_investments?.length})
          </button>
          <button
            onClick={() => setType("apartment")}
            className={type === "apartment" ? "current" : ""}
          >
            Apartamenty ({favorite_apartments?.length})
          </button>
        </div>

        <div className="row">
          {data.length > 0 ? (
            data.map((item, index) =>
              type === "investment" ? (
                <div className="col-12" key={index}>
                  <InvestmentTile item={item} />
                </div>
              ) : (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <ApartmentTile item={item} />
                </div>
              )
            )
          ) : (
            <p className="favorites-content__info">
              Brak pozycji do wyświetlenia..
            </p>
          )}
        </div>
        <div className="favorites-content__cta">
          {type === "investment" ? (
            <Button to="/portfel-nieruchomosci/">Dodaj inwestycję</Button>
          ) : (
            <Button to="/portfel-nieruchomosci/">Dodaj apartament</Button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
