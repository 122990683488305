import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import FavoriteCompare from "components/FavoriteCompare"
import LazyFadeImage from "components/LazyFadeImage"

import { usePopup } from "context/popup"

const ApartmentTile = ({ item, showAddress }) => {
  const { setPopup } = usePopup()

  return (
    <Link
      to={`${
        item?.acfApartment?.isStandalone
          ? `/apartamenty/${item.slug}/`
          : item?.acfApartment?.investment[0]?.uri
      }${!item?.acfApartment?.isStandalone ? `${item?.slug}/` : ""}`}
      className={`apartment-tile${
        item?.acfApartment?.status === "sold" ? " apartment-tile--sold" : ""
      }`}
    >
      <div className="apartment-tile__image">
        {item?.acfApartment?.status === "sold" ? (
          <h3>Sprzedane</h3>
        ) : (
          <LazyFadeImage>
            <img
              className="apartment-tile__image-view"
              src={
                item?.acfApartment?.planLink
                  ? item?.acfApartment?.planLink
                  : item?.acfApartment?.visualization2d?.localFile?.publicURL
              }
              alt=""
            />
          </LazyFadeImage>
        )}
        {!item?.acfApartment?.isStandalone && (
          <img
            className="apartment-tile__logo"
            src={
              item?.acfApartment?.investment[0]?.acfInvestment?.logo?.localFile
                ?.publicURL
            }
            alt=""
          />
        )}
        {item?.acfApartment?.status !== "sold" && (
          <FavoriteCompare id={item?.id} type={"apartment"} />
        )}
        <div className="apartment-tile__hover">Zobacz</div>
      </div>
      <div className="row">
        {showAddress && (
          <div className="col-12">
            <small>Adres</small>
            <p className="apartment-tile__address">
              {item?.acfApartment?.address
                ? item?.acfApartment?.address
                : item?.acfApartment?.investment
                ? item?.acfApartment?.investment[0]?.acfInvestment?.address
                : null}
            </p>
          </div>
        )}
        <div className="col-sm-3 col-4">
          <small>Nr apartamentu</small>
          <p>
            {item?.acfApartment?.localNumber
              ? item?.acfApartment?.localNumber
              : item?.title}
          </p>
        </div>
        <div className="col-sm-3 col-4">
          <small>Metraż</small>
          <p>
            {item?.acfApartment?.area}m<sup>2</sup>
          </p>
        </div>
        <div className="col-sm-3 col-4">
          <small>Liczba pokoi</small>
          <p>{item?.acfApartment?.roomsnumber}</p>
        </div>
        <div className="col-sm-3 col-3">
          <small>Piętro</small>
          <p>
            {item?.acfApartment?.floor === null ? 0 : item?.acfApartment?.floor}
          </p>
        </div>
        <div
          className={
            item?.acfApartment?.status === "reserved"
              ? "col-sm-4 col-4"
              : "col-sm-3 col-4"
          }
        >
          <small>Status</small>
          <p>
            {item?.acfApartment?.status === "available"
              ? "wolne"
              : item?.acfApartment?.status === "reserved"
              ? "zarezerwowane"
              : "sprzedane"}
          </p>
        </div>
        {item?.acfApartment?.price && (
          <div
            className={
              item?.acfApartment?.status === "reserved"
                ? "col-sm-8"
                : "col-sm-9"
            }
          >
            <small>Cena</small>
            <p
              className={`price${
                item?.acfApartment?.pricepromo ? " promo" : ""
              }`}
            >
              <span>
                {item?.acfApartment?.price &&
                  item?.acfApartment?.price?.toLocaleString()}{" "}
                zł
              </span>{" "}
              {item?.acfApartment?.pricepromo && (
                <>{item?.acfApartment?.pricepromo.toLocaleString()} zł</>
              )}
            </p>
          </div>
        )}
      </div>
      <button
        className="link"
        onClick={e => {
          e.preventDefault()
          setPopup(
            `Apartament ${
              item?.acfApartment?.localNumber
                ? item?.acfApartment?.localNumber
                : item?.title
            }`
          )
        }}
      >
        Zapytaj o apartament
      </button>
    </Link>
  )
}

export default ApartmentTile
