import "./styles.scss"

import React from "react"

import ContactForm from "components/ContactForm"
import Checkbox from "components/Checkbox"

import { useComparedInvestments } from "context/investments-comparison"
import { useComparedApartments } from "context/apartments-comparison"
import { useFavoriteInvestments } from "context/investments-favorites"
import { useFavoriteApartments } from "context/apartments-favorites"

const ContactFavCompare = ({ type, variant }) => {
  const { compared_investments } = useComparedInvestments()
  const { compared_apartments } = useComparedApartments()
  const { favorite_investments } = useFavoriteInvestments()
  const { favorite_apartments } = useFavoriteApartments()

  const data =
    variant === "compare" && type === "investment"
      ? compared_investments
      : variant === "compare" && type === "apartment"
      ? compared_apartments
      : variant === "favorites" && type === "investment"
      ? favorite_investments
      : variant === "favorites" && type === "apartment"
      ? favorite_apartments
      : null

  return (
    <section className="contact-fav-compare">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            <h3>
              {type === "investment"
                ? "Chciałbym zapytać o inwestycje:"
                : "Chciałbym zapytać o apartamenty:"}
            </h3>
            <div className="contact-fav-compare__list">
              {data?.map((item, index) => (
                <Checkbox
                  className="checkbox--regular"
                  key={index}
                  checked
                  disabled
                >
                  {item?.title}
                  {!item?.acfApartment?.isStandalone &&
                    item?.acfApartment?.investment[0]?.title &&
                    ` - ${item?.acfApartment?.investment[0]?.title}`}
                </Checkbox>
              ))}
              <small>
                Wybrane oferty zostaną dołączone do Twojego zapytania, aby nasz
                specjalista mógł udzielić Ci wszystkich informacji.
              </small>
            </div>
          </div>

          <div className="col-md-6 offset-md-1">
            <h3>Skontaktuj się z nami</h3>
            <ContactForm dataFavCompare={data} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactFavCompare
