import React, { useState } from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactFavCompare from "components/ContactFavCompare"

import { Content } from "page_components/favorites"

const Favorites = () => {
  const title = "Ulubione"
  const [type, setType] = useState("investment")

  const comparisonData = {
    type,
    setType,
  }

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content {...comparisonData} />
      <ContactFavCompare {...comparisonData} variant="favorites" />
    </Layout>
  )
}

export default Favorites
